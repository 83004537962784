@font-face {
  font-family: 'NeueMontrealBold';
  src: url('./fonts/NeueMontreal/NeueMontreal-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'NeueMontrealItalic';
  src: url('./fonts/NeueMontreal/NeueMontreal-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'NeueMontrealMedium';
  src: url('./fonts/NeueMontreal/NeueMontreal-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'NeueMontreal';
  src: url('./fonts/NeueMontreal/NeueMontreal-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'RotaBold';
  src: url('./fonts/Rota/Rota-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'RotaSemiBold';
  src: url('./fonts/Rota/Rota-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'RotaExtraBold';
  src: url('./fonts/Rota/Rota-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'RotaItalic';
  src: url('./fonts/Rota/Rota-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Rota';
  src: url('./fonts/Rota/Rota-Regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  -ms-overflow-style: none;
}

#root {
  padding: 0;
  height: 100%;
  width: 100%;
}

.App {
  background-image: url('./assets/img-bg-elipse.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid #e8ebf5;
  background-clip: padding-box;
  border-radius: 10px;
}

#preview-container ol,
#preview-container ul,
#broadcast-detail-card ol,
#broadcast-detail-card ul {
  padding-left: 40px;
}

#preview-container p,
#broadcast-detail-card p {
  margin: 16px 0;
}

.border-selected-gradient {
  border-radius: 50px;
  border: 10px solid transparent;
  background-image: linear-gradient(246.39deg, #f8d8c4 -2.52%, #bdbcff 81.43%);
  background-origin: border-box;
  -webkit-mask: linear-gradient(#fff 0px, #fff 0px) padding-box padding-box,
    linear-gradient(#fff 0px, #fff 0px);
  -webkit-mask-composite: source-out;
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}